import React from "react";
import { Instagram, Spotify } from "../Assets/Logos";
import ContactUsInput from "./ContactUsInput";

import StunifyAnimatedBg from "./StunifyAnimatedBg";
import { useInView } from "react-intersection-observer";
import { useScroll } from "../Context/ScrollContext";
import { useTheme } from "../Context/ThemeContext";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const { videoBg } = useTheme();

  const { ref, inView } = useInView();
  const { ContactUsRef } = useScroll();

  return (
    <div
      ref={ContactUsRef}
      className=" overflow-hidden  mt-24 h-fit flex flex-col max-md:items-center lg:px-[15svw] px-[5svw]"
      id="contact">
      <div className="absolute flex items-center right-0  w-screen h-screen justify-center">
        {inView && (
          <StunifyAnimatedBg
            videoBg={videoBg}
            className={
              " animate__animated animate__fadeInUpBig  z-10  md:w-[70svw] max-w-[820px]    max-md:w-[90%]  "
            }
          />
        )}
      </div>

      <div className="z-20 w-full justify-center items-center">
        <h1 className="font-Syne text-[2.5rem] max-md:text-center font-bold uppercase text-[#232534] dark:text-white">
          Contact Us
        </h1>

        <div className="flex justify-between max-md:justify-center">
          <div className="w-[300px]">
            <p className="font-Sora text-[#232534] dark:text-white text-sm font-semibold max-md:text-center">
              Thank you for your interest in Stunify Music Festival!
              <br /> We're here to help you with any questions or concerns you may have.{" "}
            </p>
            <p className="font-Sora text-[#232534] dark:text-white text-sm font-semibold mt-5 max-md:text-center">
              For general inquiries:
            </p>
            <p
              ref={ref}
              className="font-Sora text-[#232534] dark:text-white text-sm font-extrabold max-md:text-center ">
              Stunify-musicfestival@stunify.io
            </p>
          </div>
          <div className="flex flex-col justify-end w-[212px] max-md:hidden">
            <div className="flex justify-between items-center">
              <p className="font-Sora flex justify-center leading-4 text-[#232534] dark:text-white text-sm font-semibold">
                Stay Connected:
              </p>
              <Link to="https://www.instagram.com/stunify.festival/">
                <Instagram />
              </Link>
            </div>
            <div className="flex justify-between items-center">
              <p className="font-Sora flex justify-center leading-4 text-[#232534] dark:text-white text-sm font-semibold">
                Stay tuned listen to:
              </p>
              <Link to="https://open.spotify.com/playlist/7vPfFoGWedfg8x0gqWI64V?si=l_PDMCkFQA-fCAxH0jYtCA&pi=e-AQVRcS5kR1-5">
                <Spotify />
              </Link>
            </div>
          </div>
        </div>
        <ContactUsInput />
        <div className="-translate-y-[28rem]" ref={ref}></div>
      </div>
    </div>
  );
};

export default ContactUs;
