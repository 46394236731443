import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";
import "../Utils/toastStyles.css";
import { useTheme } from "../Context/ThemeContext";

const ContactUsInput = () => {
  const {theme}= useTheme()
  const form = useRef()
  const {
    resetField,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
      
    emailjs.sendForm('service_ichpvtu','template_img88z9', form.current, {
      publicKey: 'KD7ElgwEi36gu2TwH',
    })
    .then(
      () => {
        resetField('firstName');
        resetField('lastName');
        resetField('email');
        resetField('message');
       toast('Message sent! We\'ll be in touch soon.',);
      },
      (error) => {
       
      },
    );
  };
  return (
    <form
    ref={form}
      onSubmit={handleSubmit(onSubmit)}
      className="flex-col flex w-full   justify-center mt-9">
      <div className="md:flex  gap-10  w-full">
        <div className="flex flex-col grow gap-3 mb-5">
          <label className="labelText dark:text-white">First name*</label>
          <input
            {...register("firstName", { required: true })}
            type="text"
            className={`${errors.firstName ? "!border-red-500 outline-red-500" : ""} contactInput h-[52px] grow dark:text-white dark:border-[1px] dark:border-white font-Syne px-3`}
          />
        </div>
        <div className="flex flex-col grow gap-3 mb-5">
          <label className="labelText dark:text-white ">Last name*</label>
          <input
            {...register("lastName", { required: true })}
            type="text"
            className={`${errors.lastName ? "!border-red-500 outline-red-500" : ""} contactInput h-[52px]    dark:text-white dark:border-[1px] dark:border-white font-Syne px-3`}
          />
        </div>
      </div>
      <label className="labelText dark:text-white mb-3">Email*</label>
      <input
        name="from_email"
        {...register("email", { required: true })}
        type="email"
        className= { `${errors.email ? "!border-red-500 outline-red-500" : ""} contactInput h-[52px] mb-5 dark:text-white dark:border-[1px] dark:border-white font-Syne px-3`}
      />
      <label className="labelText dark:text-white mb-3">Comment or Message*</label>
      <textarea
        {...register("message", { required: true })}
        cols={5}
        rows={10}
        className={`${errors.message ? "!border-red-500 outline-red-500" : ""} contactInput dark:text-white dark:border-[1px] dark:border-white font-Syne px-3`}></textarea>
      <button
        type="submit"
  
        className="uppercase text-center max-md:mx-auto font-Syne bgFadeinLeft hover:text-white font-medium text-sm rounded-[29px] border-[1px] dark:border-white dark:text-white  border-[#232534] w-[159px] h-[42px] mt-5">
        contact us
      </button>
    </form>
  );
};

export default ContactUsInput;
