import React, { useState } from "react";


import { StunifyLogo, StunifyStar } from "../Assets/Logos";
import { useScroll } from "../Context/ScrollContext";

const HeaderAnimatedLogo = ({handleLink}) => {
  const { heroRef} = useScroll();

  const [hover, setHover] = useState(false);
  const [animation, setAnimation] = useState({});
  return (
    <div
      onClick={()=>{handleLink(heroRef)}}
      className="cursor-pointer"
        onMouseOver={() => {
          setHover(true);
          setAnimation({ scale: [1, 0] });
        }}
        onMouseLeave={() => {
          setHover(false);
          setAnimation({ scale: [0, 1] });
        }}
        >
      {hover && <StunifyStar />}
      {<StunifyLogo animation={animation} className={"absolute top-0 dark:fill-white"} />}
    </div>
  );
};

export default HeaderAnimatedLogo;