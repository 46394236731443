import React, { useEffect } from "react";
import Footer from "../Components/Footer";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-10">
      <div className="flex  mx-auto justify-center gap-4 mt-10 font-Syne dark:text-white">
        <div className="hidden md:flex flex-col  lg:min-w-[15svw] min-w-[5svw] pl-16 text-right mt-32">
          <a href="#1" className="font-semibold">
            Introduction
          </a>
          <a href="#2" className="font-semibold">
            Information We Collect
          </a>
          <a href="#3" className="font-semibold">
            How We Use Your Information
          </a>
          <a href="#4" className="font-semibold">
            Sharing your information
          </a>
          <a href="#5" className="font-semibold">
            Security of Data
          </a>
        </div>

        <div className="lg:pr-[15svw] pr-[5svw] p-14 md:p-2 ">
          <h3 className="font-bold text-2xl mb-14 ">
            Privacy Policy for
            <br />
            Stunify Music Festival
          </h3>
          <div id="1">
            <h4 className="font-bold">Introduction</h4>
            <p>
              Welcome to the Stunify Music Festival! We are committed to protecting the
              privacy and security of our users' information. This Privacy Policy outlines
              our practices concerning the collection, use, and disclosure of your
              information when you use our website and services associated with the
              festival.
            </p>
          </div>
          <div className="mt-8" id="2">
            <h4 className="font-bold">Information We Collect</h4>
            <p className="font-semibold">Personal Information</p>
            <p>
              Identifying Information: Name, email address, phone number, and postal
              address when you purchase tickets or sign up for updates.
            </p>
            <p>
              Payment Information: Credit card details and billing information, processed
              securely through our payment gateway provider, are not stored on our
              servers.{" "}
            </p>

            <p className="font-semibold mt-6">Non-Personal Information</p>
            <p>
              Usage Data: Information on how you access and use our website, including
              your IP address, browser type, and version, pages you visit, time and date
              of your visit, time spent on those pages, and other diagnostic data.{" "}
            </p>
            <p>
              Cookies and Tracking Technologies: We use cookies and similar tracking
              technologies to track activity on our website and store certain information.
              You can instruct your browser to refuse all cookies or indicate when a
              cookie is being sent.
            </p>
          </div>
          <div className="mt-8" id="3">
            <h4 className="font-bold">How We Use Your Information</h4>
            <ul className="list-disc gap-2 mt-4">
              <li>
                To provide and maintain our service, including monitoring the usage of our
                service.
              </li>
              <li>
                To manage your booking and registration for the festival, including ticket
                purchases and confirmations.
              </li>
              <li>
                To contact you with newsletters, marketing or promotional materials, and
                other information that may be of interest to you.{" "}
              </li>
              <li>For customer support and to respond to your queries.</li>
              <li>To detect, prevent, and address technical issues.</li>
            </ul>
          </div>

          <div className="mt-8" id="4">
            <h4 className="font-bold">Sharing Your Information</h4>
            <p className="font-semibold mt-6">Service Providers</p>
            <p>
              We may employ third-party companies and individuals to facilitate our
              service, provide the service on our behalf, perform service-related
              activities, or assist us in analyzing how our service is used. These third
              parties have access to your Personal Information only to perform these tasks
              on our behalf and are obligated not to disclose or use it for any other
              purpose.
            </p>

            <p className="font-semibold mt-6">Legal Requirements</p>
            <p>
              Stunify Music Festival may disclose your Personal Information in the good
              faith belief that such action is necessary to:
            </p>
            <ul className="list-disc gap-2 mt-4">
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of Stunify Music Festival</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with the service
              </li>
              <li>Protect the personal safety of users of the service or the public</li>
              <li>Protect against legal liability</li>
            </ul>
          </div>

          <div className="mt-8">
            <h4 className="font-bold" id="5">
              Security of Data
            </h4>
            <p>
              We value your trust in providing us your Personal Information, thus we
              strive to use commercially acceptable means of protecting it. However, no
              method of transmission over the internet or method of electronic storage is
              100% secure and we cannot guarantee its absolute security.
            </p>

            <p className="font-semibold mt-6">Your Data Protection Rights</p>
            <p>
              You have certain data protection rights, including the right to access,
              update, or delete the information we have on you, the right of
              rectification, the right to object, the right of restriction, the right to
              data portability, and the right to withdraw consent.{" "}
            </p>

            <p className="font-semibold mt-6">Changes to This Privacy Policy</p>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of
              any changes by posting the new Privacy Policy on this page and updating the
              "effective date" at the top of this policy.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
