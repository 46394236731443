import React, { useState, useEffect } from 'react';

const FeatureDetection = () => {
  const [supportsWebP, setSupportsWebP] = useState(false);
  const [supportsWebM, setSupportsWebM] = useState(false);

  useEffect(() => {
    // Check WebP support
    const checkWebP = async () => {
      const webPSupport = await testWebP();
      setSupportsWebP(webPSupport);
    };

    // Check WebM support
    const checkWebM = () => {
      const video = document.createElement('video');
      setSupportsWebM(video.canPlayType('video/webm; codecs="vp8, vorbis"') !== '');
    };

    checkWebP();
    checkWebM();
  }, []);

  const testWebP = () => {
    return new Promise(resolve => {
      const webP = new Image();
      webP.onload = webP.onerror = function () {
        resolve(webP.height === 2);
      };
      webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    });
  };

  return (
    <div>
      <h2>Feature Detection Results:</h2>
      <p>WebP support: {supportsWebP ? 'Yes' : 'No'}</p>
      <p>WebM support: {supportsWebM ? 'Yes' : 'No'}</p>
    </div>
  );
};

export default FeatureDetection;