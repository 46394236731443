import React from "react";
import Footer from "../Components/Footer";

const Health = () => {
  return (
    <div className="mt-10">
      <div className="flex mx-auto justify-center gap-4 font-Syne dark:text-white">
        <div className="hidden md:flex flex-col  lg:min-w-[15svw] min-w-[5svw] pl-16 text-right mt-32">
          <a href="#1" className="font-semibold">
            General Safety Measures
          </a>
          <a href="#2" className="font-semibold">
            COVID-19 Specific Guidelines
          </a>
          <a href="#3" className="font-semibold">
            Personal Safety Tips
          </a>
          <a href="#4" className="font-semibold">
            Reporting Issues
          </a>
        </div>

        <div className="lg:pr-[15svw] pr-[5svw] p-14 md:p-2 ">
          <h3 className="font-bold text-2xl mb-14 ">
            Health and Safety at <br />
            Stunify Music Festival
          </h3>
          <p className="my-6">
            Welcome to Stunify Music Festival! Your safety and well-being are our top
            priorities. To ensure everyone enjoys a safe and memorable experience, please
            review and adhere to the following health and safety guidelines:
          </p>

          <div id="1">
            <h4 className="font-bold">General Safety Measures</h4>
            <p className="font-semibold">Event Securit</p>
            <p>
              Stunify Music Festival employs comprehensive security measures including
              on-site security personnel, surveillance systems, and entry checks to ensure
              the safety of all attendees.
            </p>

            <p className="font-semibold mt-6">Emergency Services</p>
            <p>
              Medical personnel will be available on-site to handle any health-related
              issues. Emergency contact numbers and information booths will also be
              accessible throughout the festival grounds.
            </p>

            <p className="font-semibold mt-6">Lost and Found</p>
            <p>
              Lost items can be reported and retrieved at designated Lost and Found
              centers. Festival organizers are not responsible for lost or stolen items
              but will assist in any way possible to help recover lost goods.
            </p>
          </div>

          <div className="mt-8" id="2">
            <h4 className="font-bold">COVID-19 Specific Guidelines</h4>
            <p className="font-semibold">Vaccination</p>
            <p>
              All attendees, staff, and performers are encouraged to be fully vaccinated
              against COVID-19. Proof of vaccination may be required for entry, depending
              on current health regulations.
            </p>

            <p className="font-semibold mt-6">Weather-Related Safety</p>

            <p className="font-semibold mt-6">General Preparedness</p>
            <p>
              The festival takes place rain or shine. Please come prepared for varying
              weather conditions. In case of severe weather, information will be
              communicated through the festival’s official channels including PA
              announcements, social media, and our mobile app.{" "}
            </p>

            <p className="font-semibold mt-6">Shelter and Evacuation</p>
            <p>
              Emergency shelters and evacuation routes are clearly marked throughout the
              festival grounds. Attendees should familiarize themselves with the nearest
              exits and follow all instructions from festival staff during emergencies.
            </p>
          </div>

          <div className="mt-8" id="3">
            <h4 className="font-bold">Personal Safety Tips</h4>
            <p className="font-semibold">Stay Hydrated</p>
            <p>
              Drink plenty of water throughout the day, especially in hot weather. Water
              stations will be available throughout the venue..
            </p>

            <p className="font-semibold mt-6">Moderate Alcohol Consumption</p>
            <p>
              Alcohol will be available to attendees of legal drinking age. Please consume
              responsibly to ensure your safety and the safety of others.
            </p>

            <p className="font-semibold mt-6">Buddy System</p>
            <p>Stay with friends or groups as much as possible, especially after dark.</p>
          </div>

          <div className="mt-8" id="4">
            <h4 className="font-bold">Reporting Issues</h4>
            <p>
              If you encounter any safety concerns or suspicious activity, please report
              it immediately to the nearest security staff or festival official.{" "}
            </p>
            <p className="font-semibold mt-6">
              By attending Stunify Music Festival, you agree to comply with these health
              and safety measures.
            </p>
            <p>
              Your cooperation is vital to keeping our festival safe for everyone. Thank
              you for helping us create a secure and enjoyable environment.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Health;
