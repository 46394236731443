import React from "react";
import { useScroll } from "../Context/ScrollContext";

const Ticket = ({ state, name, price }) => {
  return (
    <div >
      {state === 1 && (
        <div className="flex flex-col   py-3 px-7 border-[1px] border-[#498BEC] text-[#498BEC] dark:text-[#4FD7E4] dark:border-[#4FD7E4] w-[210px] h-[121px] rounded-xl">
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold font-Sora">{name}</h2>
            <h3 className="text-xl font-Syne">{price}</h3>
          </div>
          <div>
            <h2 className="text-2xl font-bold font-Sora">SOLD OUT</h2>
          </div>
        </div>
      )}

      {state === 2 && (
        <div className="flex flex-col   py-3 px-7 border-[1px] bg-[#232534] dark:bg-white dark:text-black text-[#CBD9F2] w-[210px] h-fit rounded-xl">
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold font-Sora">{name}</h2>
            <h3 className="text-xl font-Syne">{price}</h3>
          </div>
          <div className="my-2">
            <a href="https://tunis.events/fr/events/3N4lnk#" className=" w-24 h-8 hborder-[1px] dark:hover:bg-black dark:hover:text-white dark:border-black dark:text-black border-[#CBD9F2] border-[1px] font-Sora text-[#CBD9F2] justify-center mx-auto text-sm items-center flex hover:cursor-pointer hover:text-black  hover:bg-white rounded-full ">
              Buy Tickets
            </a>
          </div>
        </div>
      )}

      {state === 3 && (
        <div className="flex flex-col   py-3 px-7 border-[1px] dark:border-white dark:text-white border-[#232534] text-[#232534] w-[210px] h-[121px] rounded-xl">
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold font-Sora">{name}</h2>
            <h3 className="text-xl font-Syne">{price}</h3>
          </div>
          <div>
            <p>Coming Soon</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ticket;
