import React from "react";

const ArrowRight = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="30"
        viewBox="0 0 29 30"
        className="dark:fill-white"
        fill="none">
        <rect
          x="27.83"
          y="27.8301"
          width="26.83"
          height="26.83"
          rx="13.415"
          transform="rotate(-180 27.83 27.8301)"
          stroke="#232534"
          strokeWidth="2"
        />
        <g filter="url(#filter0_d_894_333)">
          <path
            d="M18.2084 14.7185L12.6002 20.3267L12.2179 19.9443L17.4437 14.7185L12.2179 9.49265L12.6002 9.11027L18.2084 14.7185Z"
            fill="#232534"
          />
          <path
            d="M18.562 15.072L18.9155 14.7185L18.562 14.3649L12.9538 8.75672L12.6002 8.40316L12.2467 8.75672L11.8643 9.13909L11.5108 9.49265L11.8643 9.8462L16.7366 14.7185L11.8643 19.5907L11.5108 19.9443L11.8643 20.2978L12.2467 20.6802L12.6002 21.0338L12.9538 20.6802L18.562 15.072Z"
            stroke="#232534"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_894_333"
            x="6.80365"
            y="7.69629"
            width="16.819"
            height="22.0444"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.533333 0 0 0 0 0.666667 0 0 0 0 0.921569 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_894_333"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_894_333"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowRight;
