export const lineups = [
  {
    name: "Special Guest",
    desciption: "",
    img: "../images/lineup1.png",
    day: 1,
  },
  {
    name: "Special Guest",
    desciption: "",
    img: "../images/lineup1.png",
    day: 2,
  },
  {
    name: "Olly James",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Olly+James.webp",
    day: 1,
  },
  {
    name: "Ubbah",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Ubbah.webp",
    day: 1,
  },
  {
    name: "Kadhem",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Kadhem.webp",
    day: 1,
  },
  {
    name: "Irenee S",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Irenee+S.webp",
    day: 2,
  },
  {
    name: "D'maselle",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/D'maselle.webp",
    day: 2,
  },
  {
    name: "Adassiya",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Adassiya.webp",
    day: 2,
  },
  {
    name: "Special K",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Special+K.webp",
    day: 2,
  },
  {
    name: "Archangel28",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Archangel28.webp",
    day: 1,
  },
  {
    name: "JASMIN",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/JASMIN.webp",
    day: 2,
  },
  {
    name: "Bayou",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Bayou.webp",
    day: 1,
  },

  {
    name: "HearThug",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/HearThug.webp",
    day: 1,
  },
  {
    name: "Wail Bouri",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Wail+Bouri.webp",
    day: 2,
  },
  {
    name: "Ben Deeper",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Ben+Deeper.webp",
    day: 1,
  },
  {
    name: "Adnyl",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Adnyl.webp",
    day: 2,
  },
  {
    name: "Baqoosh",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Baqoosh.webp",
    day: 1,
  },

  {
    name: "Kilany M",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Kilany+M.webp",
    day: 1,
  },
  {
    name: "Ismail Ben Aissa",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/Ismail+Ben+Aissa.webp",
    day: 2,
  },
  {
    name: "KÖSH",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/KÖSH.webp",
    day: 1,
  },
  {
    name: "OUTRAGE",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/OUTRAGE.webp",
    day: 1,
  },
  {
    name: "YOÜBI",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/YOÜBI.webp",
    day: 2,
  },

  {
    name: "FERY",
    desciption: "Coachella, Electric Daisy Carnival",
    img: "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/Lineup/FERY.webp",
    day: 1,
  },
];
