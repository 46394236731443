import React, { useEffect, useRef } from "react";
import "animate.css";
import { useTheme } from "../Context/ThemeContext";

const StunifyAnimatedBg = ({ className, videoBg,  }) => {
  const {setVideoLoaded} = useTheme()
  const videoRef = useRef();

  useEffect(() => {    

    videoRef.current?.load();
  }, [videoBg]);

  return (
   
      <video onLoadedData={()=>{setVideoLoaded(true)}} ref={videoRef} id="banner-video" playsInline  controlsList="nodownload nofullscreen noremoteplayback"  autoPlay muted loop className={`${className} hide-controls`}>
        <source src={videoBg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    
  );
};

export default StunifyAnimatedBg;
