import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";


const Header = ({ theme, handleThemeSwitch }) => {
  return (
    <div className="relative h-fit w-fit ">
      <DesktopHeader theme={theme} handleThemeSwitch={handleThemeSwitch} /> 
      <MobileHeader theme={theme} handleThemeSwitch={handleThemeSwitch} />
    </div>
  );
};

export default Header;
