import React, { useState } from "react";
import ArrowLeft from "../Utils/ArrowLeft";
import ArrowRight from "../Utils/ArrowRight";
import { useScroll } from "../Context/ScrollContext";
import { useTheme } from "../Context/ThemeContext";
import StunifyAnimatedBg from "./StunifyAnimatedBg";
import { useInView } from "react-intersection-observer";

const Hero = () => {
  const { heroRef } = useScroll();
  const { theme, videoBg } = useTheme();
  const { ref, inView } = useInView();


  const [hero, setSelectedHero] = useState(0);

  const changeCarousel = (state) => {
    if (state === "next") {
      if (hero === 2) {
        setSelectedHero(0);
      } else {
        setSelectedHero(hero + 1);
      }
    } else {
      if (hero === 0) {
        setSelectedHero(2);
      } else {
        setSelectedHero(hero - 1);
      }
    }
  };

  let carousel = [
    {
      id: 0,
      title: "Our",
      subTitle: "Mission",
      text: "Our mission is to create a festival that celebrates diversity in music while promoting environmental sustainability and social responsibility. We aim to provide a platform for both emerging and established artists, support local businesses, and contribute to charitable causes.",
    },
    {
      id: 1,
      title: "Experience",
      subTitle: "The Spectacle",
      text: "Join us on August 10th and 11th at Golf Flamingo Monastir for a unique experience that combines the best of Afro House and Melodic Techno. Immerse yourself in two days of incredible music, interactive activities, and unforgettable moments.",
    },
    {
      id: 2,
      title: "About",
      subTitle: "SMF",
      text: "Stunify Music Festival is a premier electronic music event featuring an eclectic lineup of international and local artists. As a festival originating from Switzerland, we focus on sustainability and social responsibility, offering more than just music. Experience a blend of art, wellness, and community engagement at one of Tunisia's most beautiful locations.",
    },
  ];

  return (
    <div
      
      ref={heroRef}
      className="z-10 flex md:h-[369px] items-center sm:flex-col md:flex-row justify-center w-full  max-md:flex-col md:items-center lg:px-[15svw] px-[5svw]">
      <div className="z-10 flex md:w-1/2 justify-start  max-md:justify-center">
    {<StunifyAnimatedBg
          
          videoBg={videoBg}
          className={
            "  h-full w-[120%] max-md:h-[400px] md:max-w-[450px]   md:-translate-x-10   "
          }
        />}
     
      </div>
      <div ref={ref} className="flex z-20 relative flex-col h-[70%]   md:w-3/4  text-right font-Syne">
        <h4 className="text-[#498BEC]  text-5xl font-bold max-md:text-center md:h-fit max-md:h-[144px]">
          {carousel[hero].title}
          <br />
          {carousel[hero].subTitle}
        </h4>
        <div className="max-md:text-center dark:text-white md:h-3/4 max-md:h-[200px] flex items-center justify-center max-md:text-sm ">
          {carousel[hero].text}
        </div>
        <div className="md:absolute bottom-[-20px] right-0 flex flex-row-reverse gap-5 max-md:justify-center   ">
          <ArrowRight
            className="hover:cursor-pointer"
            onClick={() => changeCarousel("next")}
          />
          <ArrowLeft
            className="hover:cursor-pointer"
            onClick={() => changeCarousel("prev")}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
