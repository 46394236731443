import React from "react";
import { StunifyLogo } from "../Assets/Logos";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="mt-8 md:mt-24  mb-8 z-10">
      <div className="z-10 flex justify-between max-md:justify-center items-center lg:px-[15svw] px-[5svw] gap-3 max-md:flex-col ">
        <StunifyLogo className={"dark:fill-white -translate-x-5 z-10"} />
        <div className="flex z-10 grow justify-between max-md:flex-col max-md:gap-4">
          <Link
            to="/privacy"
            className="text-xs font-Sora font-extrabold text-[#232534] dark:text-white">
            Privacy Policy
          </Link>
          <Link
            to="/terms-of-use"
            className="text-xs font-Sora font-extrabold text-[#232534] dark:text-white">
            Terms of Use
          </Link>
          <Link
            to="/terms-and-conditions"
            className="text-xs font-Sora font-extrabold text-[#232534] dark:text-white">
            Festival Tickets Terms
          </Link>
          <Link
            to="/security"
            className="text-xs font-Sora font-extrabold text-[#232534] dark:text-white">
            Security
          </Link>
          <Link
            to="/health-and-safety"
            className="text-xs font-Sora font-extrabold text-[#232534] dark:text-white">
            Health and Safety
          </Link>
          <div className="flex gap-3 md:hidden">
            <Link
              to="https://www.instagram.com/stunify.festival/"
              className="text-xs font-Sora font-extrabold text-[#232534] dark:text-white">
              <FaInstagram size={24} />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=61560078878083"
              className="text-xs font-Sora font-extrabold text-[#232534] dark:text-white">
              <FaFacebook size={24} />
            </Link>
          </div>

          <span className="text-[0.6rem] font-Sora font-normal text-[#232534] dark:text-white">
            Copyright © 2024 Stunify
          </span>
        </div>
      </div>
      <div className="flex  gap-3 justify-center max-md:hidden">
        <Link
          to="https://www.instagram.com/stunify.festival/"
          className="text-xs font-Sora z-10 font-extrabold text-[#232534] dark:text-white">
          <FaInstagram size={24} />
        </Link>
        <Link
          to="https://www.facebook.com/profile.php?id=61560078878083"
          className="text-xs z-10 font-Sora font-extrabold text-[#232534] dark:text-white">
          <FaFacebook size={24} />
        </Link>
      </div>
    </div>
  );
};

export default Footer;
