import React from "react";
import Ticket from "./Ticket";
import { useScroll } from "../Context/ScrollContext";

const Tickets = () => {
  const { TicketRef } = useScroll();

  return (
    <div ref={TicketRef} className="lg:px-[15svw] px-[5svw]">
      <div
        className="flex flex-col font-Syne text-center max-lg:items-center max-lg:justify-center mt-16 "
        id="tickets">
        <h3 className="text-[#498BEC] dark:text-[#4FD7E4] text-4xl font-bold">
          TICKET PRICING
        </h3>
        <h4 className="text-3xl my-8 dark:text-white font-bold ">
          GENERAL ADMISSION
        </h4>
        <div className="flex  lg:flex-row lg:justify-between lg:align-middle  lg:items-center flex-col">
          <div className="flex max-lg:flex-wrap justify-center lg:flex-row gap-5 max-lg:justify-center grow">
            <Ticket state={2} name={"DAY 1"} price={"90TND / 27€"} />
            <Ticket state={2} name={"DAY 2"} price={"100TND / 30€"} />
            <Ticket state={2} name={"2 DAYS"} price={"180TND / 53€"} />
          </div>
        </div>

        <h4 className="text-3xl font-bold  my-8  uppercase dark:text-white">
          backstage passes
        </h4>

        <div className="flex  flex-col lg:flex-row  justify-center align-middle items-center">
          <div className="flex max-lg:flex-wrap  lg:flex-row justify-center gap-5 max-lg:justify-center">
            <Ticket state={2} name={"DAY 1"} price={"150TND / 45€"} />
            <Ticket state={2} name={"DAY 2"} price={"150TND / 45€"} />
            <Ticket state={2} name={"2 DAYS"} price={"280TND / 83€"} />
          </div>
        </div>
       
        <h4 className="text-3xl font-bold  my-8  uppercase dark:text-white">
          VIP  (5 people)
        </h4>
        <div className="flex flex-col lg:flex-row justify-center align-middle items-center ">
          {/* <h4 className="text-3xl font-bold dark:text-white">VIP TABLES (5 people)</h4> */}
          <div className="flex max-lg:flex-wrap lg:flex-row  justify-center gap-5 max-lg:justify-center">
            <Ticket state={2} name={"DAY 1"} price={"1000TND / 300€"} />
            <Ticket state={2} name={"DAY 2"} price={"1500TND / 445€"} />
            <Ticket state={2} name={"2 DAYS"} price={"2000TND / 600€"} />
          </div>
        </div>
        <div className="items-center mt-14">
          <a
            href="https://tunis.events/fr/events/3N4lnk#"
            className="w-[241px] p-4 text-lg font-Syne bgFadeinLeft text-center content-center hover:text-white font-medium uppercase h-[58px] rounded-[29px] border-[1px] dark:border-white dark:text-white border-[#232534]">
            book your ticket
          </a>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
