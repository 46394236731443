import React from "react";
import { stunifyVillageInfos } from "../Data/StunifyVillageInfo";
import InfoCard from "./InfoCard";
import { useScroll } from "../Context/ScrollContext";

const StunifyVillage = () => {
  const { VillageRef } = useScroll();
  return (
    <div ref={VillageRef} className="lg:px-[15svw] px-[5svw]">
      <div
        className="mt-14 flex flex-col justify-center items-center w-[100%] "
        id="village">
        <h1 className="max-md:text-[#498BEC] font-Syne text-[2.5rem] font-bold text-center max-md:px-5 dark:text-[#4FD7E4]">
          STUNIFY VILLAGE
        </h1>
        <p className="text-center font-Sora mt-6 text-[#232534] dark:text-white ">
          Stunify isn't just about music, Check out what else you can do:
        </p>
        <div className="mt-14  max-md:gap-20 md:gap-[73px] grid md:grid-cols-2 md:grid-rows-4 md:w-[825px] md:h-[803px]">
          {stunifyVillageInfos.map(({ title, description }, index) => (
            <InfoCard key={index} title={title} description={description} />
          ))}
        </div>
        {/* <div className="text-center my-10 mt-16 md:mt-24 dark:text-white">
          <h3 className="text-2xl font-Syne font-semibold  ">Support for Gaza Orphans</h3>
          <p>
            Proceeds Donation: Part of the festival’s earnings will be directed to
            essential aid for orphans in Gaza.
          </p>
          <p>
            BBC Media Action Presence: BBC Media Action will engage with attendees,
            raising awareness about their mission.
          </p>
          <p>
            Community Engagement: Informative booths and interactive activities will shed
            light on the challenges faced by orphans in Gaza and how you can help.
          </p>
          <p>
            Giving Back: By attending Stunify Music Festival, you're not only enjoying
            great music but also supporting a vital cause, helping provide necessary
            resources to orphans in Gaza.
          </p>
        </div> */}
        <div className="flex flex-col gap-5">
          <p className="mt-20 text-center font-Sora text-sm dark:text-white">
            What are you waiting for then?
            <br /> Book your ticket now!
          </p>
          <a
            href="https://tunis.events/fr/events/3N4lnk#"
            className="w-[241px] text-lg font-Syne bgFadeinLeft text-center content-center hover:text-white font-medium uppercase h-[58px] rounded-[29px] border-[1px] dark:border-white dark:text-white border-[#232534]">
            {" "}
            book your ticket
          </a>
        </div>
      </div>
    </div>
  );
};

export default StunifyVillage;
