import React, { useEffect } from "react";
import Footer from "../Components/Footer";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" mt-10 ">
      <div className="flex mx-auto justify-centergap-4 font-Syne dark:text-white">
        <div className="hidden md:flex flex-col  lg:min-w-[15svw] min-w-[5svw] pl-16 text-right mt-32">
          <a href="#1" className="font-semibold">
            Purchase and Usage of Tickets
          </a>
          <a href="#2" className="font-semibold">
            Admission and Attendance
          </a>
          <a href="#3" className="font-semibold">
            Changes to the Event
          </a>
          <a href="#4" className="font-semibold">
            Health and Safety
          </a>
        </div>

        <div className="lg:pr-[15svw] pr-[5svw] p-14 md:p-2 ">
          <h3 className="font-bold text-2xl mb-14 ">
            Festival Ticket Terms and Conditions for <br />
            Stunify Music Festival
          </h3>
          <div id="1">
            <h4 className="font-bold">Purchase and Usage of Tickets</h4>
            <p className="font-semibold">Non-refundable Policy</p>
            <p>
              All ticket sales for the Stunify Music Festival are final. Tickets are
              non-refundable, non-transferable, and cannot be exchanged. Please consider
              this before purchasing your tickets.
            </p>

            <p className="font-semibold mt-6">Validity</p>
            <p>
              Tickets purchased for the Stunify Music Festival are only valid for the
              stated date(s) and time(s) of the festival. Each ticket grants the holder
              the right to attend the event according to the ticket type purchased.
            </p>

            <p className="font-semibold mt-6">Lost or Stolen Tickets</p>
            <p>
              Stunify Music Festival is not responsible for any tickets that are lost,
              stolen, or destroyed. Tickets will not be replaced under these
              circumstances.
            </p>

            <p className="font-semibold mt-6">Payment and Fees</p>
            <p>
              All prices for the festival tickets are stated in tnd. Payment must be
              received in full at the time of purchase. All ticket prices are inclusive of
              applicable taxes and fees.
            </p>
          </div>
          <div className="mt-8" id="2">
            <h4 className="font-bold">Admission and Attendance</h4>
            <p className="font-semibold">Right of Admission Reserved</p>
            <p>
              Stunify Music Festival reserves the right to refuse admission to any ticket
              holder for reasonable cause without any refund. This includes non-compliance
              with the festival's terms and conditions, unauthorized attempts to transfer
              tickets, or any inappropriate behavior that might disrupt the event.
            </p>

            <p className="font-semibold mt-6">Identification</p>
            <p>
              Attendees must present valid identification along with their tickets for
              entry into the festival. The name on the ID must match the name on the
              ticket.
            </p>

            <p className="font-semibold mt-6">Age Restrictions</p>
            <p>
              Some areas of the festival may have age restrictions depending on the nature
              of the content or performance. Please check the festival’s website for
              specific details.
            </p>

            <p className="font-semibold mt-6">Security Checks</p>
            <p>
              {" "}
              For the safety of all festival-goers, Stunify Music Festival will conduct
              security checks at the entry points. Attendees may be subject to search at
              the discretion of the festival security staff.
            </p>
          </div>

          <div className="mt-8" id="3">
            <h4 className="font-bold">Changes to the Event</h4>
            <p className="font-semibold">Event Changes or Cancellation</p>
            <p>
              Stunify Music Festival reserves the right to make changes to the event
              schedule or program as necessary. In the event of cancellation of the
              festival, the liability of the organizers will be limited to the refund of
              any tickets already purchased which shall be the sole remedy under such
              circumstances.
            </p>

            <p className="font-semibold mt-6">Weather Conditions</p>
            <p>
              The festival is a rain-or-shine event. Weather conditions are not grounds
              for a refund.
            </p>
          </div>

          <div className="mt-8" id="4">
            <h4 className="font-bold">Health and Safety</h4>
            <p className="font-semibold">Compliance with Health Guidelines</p>
            <p>
              {" "}
              Attendees must comply with any health and safety guidelines or regulations
              set by the festival organizers or local health authorities, especially
              concerning public health concerns.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
