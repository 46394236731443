import React, { useEffect } from 'react';
import Footer from "../Components/Footer";


const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="mt-10">
        <div className='flex mx-auto justify-center  gap-4 font-Syne dark:text-white'>

            <div className='hidden md:flex flex-col  lg:min-w-[15svw] min-w-[5svw] pl-16 text-right mt-32'>
                <a href="#1" className='font-semibold'>Agreement to Terms</a>
                <a href="#2" className='font-semibold'>Intellectual Property</a>
                <a href="#3" className='font-semibold'>Use License</a>
                <a href="#4" className='font-semibold'>User Behavior</a>
                <a href="#5" className='font-semibold'>Disclaimer</a>
                <a href="#6" className='font-semibold'>Limitation of Liability</a>
                <a href="#7" className='font-semibold'>Accuracy of Materials</a>
                <a href="#8" className='font-semibold'>Links</a>
                <a href="#9" className='font-semibold'>Modifications</a>
                <a href="#10" className='font-semibold'>Governing Law</a>
            </div>

            <div className='lg:pr-[15svw] pr-[5svw] p-14 md:p-2 '>
                <h3 className='font-bold text-2xl mb-14 '>Terms of Use for <br />
                    Stunify Music Festival</h3>
                <div id="1">
                    <h4 className='font-bold'>Agreement to Terms</h4>
                    <p>By accessing or using our website, you agree to be bound by these Terms of Use. If you disagree with any part of the terms, then you do not have permission to access the service.</p>
                </div>
                <div className='mt-8' id="2">
                    <h4 className='font-bold'>Intellectual Property</h4>
                    <p>The content, organization, graphics, design, compilation, and other matters related to the Stunify Music Festival website are protected under applicable copyrights and trade laws. The names, trademarks, and logos of Stunify Music Festival appearing on the site may not be used in any advertising or publicity, or otherwise to indicate Stunify Music Festival’s sponsorship or affiliation, without prior express written permission from Stunify Music Festival.</p>
                </div>
                <div className='mt-8' id="3">
                    <h4 className='font-bold'>Use License</h4>
                    <p>Permission is granted to temporarily download one copy of the materials on the Stunify Music Festival's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not: </p>
                    <ul className='list-disc gap-2 mt-4'>
                        <li>Modify or copy the materials; </li>
                        <li>Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                        <li>Attempt to decompile or reverse engineer any software contained on Stunify Music Festival's website;</li>
                        <li>Remove any copyright or other proprietary notations from the materials;</li>
                        <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
                        <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Stunify Music Festival at any time.</li>
                    </ul>
                </div>

                <div className='mt-8' id="4">
                    <h4 className='font-bold'>User Behavior</h4>
                    <p className='font-semibold mt-6'>Service Providers</p>
                    <p>You are responsible for all your activity in connection with the Services and accessing the Stunify Music Festival website.</p>

                    <p className='mt-4'>You shall not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Services.</p>
                    
                    <p className='font-semibold mt-6'>Disclaimer</p>
                    <p>The materials on the Stunify Music Festival's website are provided on an 'as is' basis. Stunify Music Festival makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
                    
                    <p className='font-semibold mt-6'>Limitation of Liability</p>
                    <p>In no event shall Stunify Music Festival or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Stunify Music Festival's website, even if Stunify Music Festival or a Stunify Music Festival authorized representative has been notified orally or in writing of the possibility of such damage.</p>

                    <p className='font-semibold mt-6'>Accuracy of Materials</p>
                    <p>The materials appearing on the Stunify Music Festival's website could include technical, typographical, or photographic errors. Stunify Music Festival does not warrant that any of the materials on its website are accurate, complete, or current.</p>

                    <p className='font-semibold mt-6'>Links</p>
                    <p>Stunify Music Festival has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Stunify Music Festival of the site. Use of any such linked website is at the user's own risk.</p>
                
                    <p className='font-semibold mt-6'>Modifications</p>
                    <p>Stunify Music Festival may revise these Terms of Use for its website at any time without notice. By using this website you are agreeing to be bound by the then-current version of these Terms of Use.</p>
                
                    <p className='font-semibold mt-6'>Governing Law</p>
                    <p>These terms and conditions are governed by and construed in accordance with the laws of stunify and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
                </div>
            </div>
        </div>
        <Footer/>
        </div>
    );
}

export default Terms;
