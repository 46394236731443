import { useEffect } from "react";
import Header from "./Components/Header";
import { Outlet } from "react-router-dom";
import { useTheme } from "./Context/ThemeContext";

const Layout = () => {
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList?.remove("dark");
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <div className="bg-[#CBD9F2] dark:bg-[#232534] h-fit relative overflow-hidden">
      <Header theme={theme} handleThemeSwitch={handleThemeSwitch} />
      <main className="h-fit md:pt-[15svh] ">
        <Outlet theme={theme} />
      </main>
    </div>
  );
};

export default Layout;
