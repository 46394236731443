import React from 'react'

const InfoCard = ({title , description}) => {
  return (
    <div className='flex flex-col gap-3 '>
      <h1 className='text-[#232534] dark:text-white font-Syne font-semibold leading-3 text-[1.6rem] md:text-center'>{title}</h1>
      <p className='text-[#232534] dark:text-white max-md:font-bold text-sm font-Sora md:text-center'>{description}</p>
    </div>
  )
}

export default InfoCard
