import React, { createContext, useContext, useEffect, useState } from "react";
import { useDeviceTest } from "./DeviceTestContext";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [videoBg, setVideoBg] = useState("");
  const [theme, setTheme] = useState("light");
  const [videoLoaded, setVideoLoaded] = useState(false);
  
  useEffect(() => {
    if (theme === "light") {
      setVideoBg(
        "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/finallight.mp4"
      );
    } else {
      setVideoBg(
        "https://d31126657dcg29.cloudfront.net/public/Stunify+Music+Festival/finaldark.mp4"
      );
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme, videoBg, videoLoaded, setVideoLoaded }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
