import React from 'react';

const Scroll = () => {
    return (
        <div className='flex w-full justify-center z-30'>
            <div className=' z-30 flex flex-col font-Sora text-[#498BEC] dark:text-white text-sm items-center gap-1 justify-center align-middle max-md:hidden'>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="31" viewBox="0 0 23 31"  className="dark:fill-white" fill="none">
                    <g clipPath="url(#clip0_517_4331)">
                        <path d="M13.8 0H9.2C6.76083 0.00256422 4.42232 0.936548 2.69756 2.59703C0.972802 4.25751 0.00266348 6.50887 0 8.85714V22.1429C0.00266348 24.4911 0.972802 26.7425 2.69756 28.403C4.42232 30.0635 6.76083 30.9974 9.2 31H13.8C16.2392 30.9974 18.5777 30.0635 20.3024 28.403C22.0272 26.7425 22.9973 24.4911 23 22.1429V8.85714C22.9973 6.50887 22.0272 4.25751 20.3024 2.59703C18.5777 0.936548 16.2392 0.00256422 13.8 0ZM20.7 22.1429C20.6981 23.9041 19.9705 25.5927 18.6769 26.838C17.3833 28.0834 15.6294 28.7839 13.8 28.7857H9.2C7.37059 28.7839 5.61665 28.0834 4.32306 26.838C3.02948 25.5927 2.3019 23.9041 2.3 22.1429V8.85714C2.3019 7.09591 3.02948 5.40734 4.32306 4.16196C5.61665 2.91658 7.37059 2.21612 9.2 2.21429H13.8C15.6294 2.21612 17.3833 2.91658 18.6769 4.16196C19.9705 5.40734 20.6981 7.09591 20.7 8.85714V22.1429Z" fill="#498BEC" />
                        <path d="M12.6501 21.6846V9.31505L14.1364 10.7474C14.2433 10.8503 14.3701 10.9319 14.5097 10.9875C14.6493 11.0432 14.799 11.0719 14.9501 11.0719C15.1012 11.0719 15.2508 11.0432 15.3904 10.9875C15.53 10.9319 15.6568 10.8503 15.7637 10.7474C15.8705 10.6445 15.9553 10.5224 16.0131 10.388C16.0709 10.2536 16.1007 10.1096 16.1007 9.96411C16.1007 9.81864 16.0709 9.67459 16.0131 9.54019C15.9553 9.40579 15.8705 9.28367 15.7637 9.18081L12.3137 5.85938C12.2069 5.75644 12.08 5.67478 11.9404 5.61906C11.8008 5.56335 11.6512 5.53467 11.5001 5.53467C11.3489 5.53467 11.1993 5.56335 11.0597 5.61906C10.9201 5.67478 10.7932 5.75644 10.6864 5.85938L7.23643 9.18081C7.02064 9.38855 6.89941 9.67031 6.89941 9.96411C6.89941 10.2579 7.02064 10.5397 7.23643 10.7474C7.45222 10.9552 7.74489 11.0719 8.05005 11.0719C8.35522 11.0719 8.64789 10.9552 8.86368 10.7474L10.3501 9.31505V21.6846L8.86368 20.2522C8.64789 20.0445 8.35522 19.9278 8.05005 19.9278C7.74489 19.9278 7.45222 20.0445 7.23643 20.2522C7.02064 20.46 6.89941 20.7417 6.89941 21.0355C6.89941 21.3293 7.02064 21.6111 7.23643 21.8188L10.6864 25.1403C10.7932 25.2432 10.9201 25.3249 11.0597 25.3806C11.1993 25.4363 11.3489 25.465 11.5001 25.465C11.6512 25.465 11.8008 25.4363 11.9404 25.3806C12.08 25.3249 12.2069 25.2432 12.3137 25.1403L15.7637 21.8188C15.9795 21.6111 16.1007 21.3293 16.1007 21.0355C16.1007 20.7417 15.9795 20.46 15.7637 20.2522C15.5479 20.0445 15.2552 19.9278 14.9501 19.9278C14.6449 19.9278 14.3522 20.0445 14.1364 20.2522L12.6501 21.6846Z" fill="#498BEC" />
                        <path d="M12.6501 45.293V32.9234L14.1364 34.3558C14.2433 34.4587 14.3701 34.5403 14.5097 34.5959C14.6493 34.6516 14.799 34.6803 14.9501 34.6803C15.1012 34.6803 15.2508 34.6516 15.3904 34.5959C15.53 34.5403 15.6568 34.4587 15.7637 34.3558C15.8705 34.2529 15.9553 34.1308 16.0131 33.9964C16.0709 33.862 16.1007 33.718 16.1007 33.5725C16.1007 33.427 16.0709 33.283 16.0131 33.1486C15.9553 33.0142 15.8705 32.8921 15.7637 32.7892L12.3137 29.4678C12.2069 29.3648 12.08 29.2832 11.9404 29.2275C11.8008 29.1717 11.6512 29.1431 11.5001 29.1431C11.3489 29.1431 11.1993 29.1717 11.0597 29.2275C10.9201 29.2832 10.7932 29.3648 10.6864 29.4678L7.23643 32.7892C7.02064 32.9969 6.89941 33.2787 6.89941 33.5725C6.89941 33.8663 7.02064 34.1481 7.23643 34.3558C7.45222 34.5636 7.74489 34.6803 8.05005 34.6803C8.35522 34.6803 8.64789 34.5636 8.86368 34.3558L10.3501 32.9234V45.293L8.86368 43.8606C8.64789 43.6529 8.35522 43.5362 8.05005 43.5362C7.74489 43.5362 7.45222 43.6529 7.23643 43.8606C7.02064 44.0684 6.89941 44.3501 6.89941 44.6439C6.89941 44.9377 7.02064 45.2195 7.23643 45.4272L10.6864 48.7487C10.7932 48.8516 10.9201 48.9333 11.0597 48.989C11.1993 49.0447 11.3489 49.0734 11.5001 49.0734C11.6512 49.0734 11.8008 49.0447 11.9404 48.989C12.08 48.9333 12.2069 48.8516 12.3137 48.7487L15.7637 45.4272C15.9795 45.2195 16.1007 44.9377 16.1007 44.6439C16.1007 44.3501 15.9795 44.0684 15.7637 43.8606C15.5479 43.6529 15.2552 43.5362 14.9501 43.5362C14.6449 43.5362 14.3522 43.6529 14.1364 43.8606L12.6501 45.293Z" fill="#498BEC" />
                    </g>
                    <defs>
                        <clipPath id="clip0_517_4331">
                            <rect width="23" height="31" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <p>Scroll down</p>
                <p>to see more details</p>
            </div>
        </div>
    );
}

export default Scroll;
