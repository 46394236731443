import { BrowserRouter, Routes, Route,} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./Layout";
import Home from "./Pages/Home";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import TermsConditions from "./Pages/TermsConditions";
import Health from "./Pages/Health";
import Security from "./Pages/Security";
import { ToastContainer } from "react-toastify";
import { useTheme } from "./Context/ThemeContext";
import "./Utils/toastStyles.css";
import Test from "./Components/Test";


export default function App() {

  const { theme } = useTheme();

  return (
    <>
      
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/test" element={<Test/>} />
              <Route path="/" element={<Home />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms-of-use" element={<Terms />} />
              <Route path="/terms-and-conditions" element={<TermsConditions />} />
              <Route path="/health-and-safety" element={<Health />} />
              <Route path="/security" element={<Security />} />
            </Route>
          </Routes>
          
        </BrowserRouter>
        <ToastContainer
        // toastClassName={() =>
          
        //   `${theme === "dark" ? "bg-white text-black" : "bg-[#498BEC] text-black"} relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer`
        // }
    
        // bodyClassName={() => "flex text-sm font-white font-med block p-3"}
        // position="top-right"
        // autoClose={3000}
        />
   
    </>
  );
}
