import React, { useEffect } from 'react';
import Footer from "../Components/Footer";


const Security = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="mt-10  ">
        <div className='flex mx-auto justify-center mb-7 min-h-fit h-screen gap-4 font-Syne dark:text-white '>

            <div className='hidden md:flex flex-col  lg:min-w-[15svw] min-w-[5svw] pl-16 text-right mt-32'>
                <a href="#1" className='font-semibold'>Security Checkpoints</a>
                <a href="#2" className='font-semibold'>Emergency Services</a>
                <a href="#3" className='font-semibold'>Lost and Found</a>
                <a href="#4" className='font-semibold'>Safe Zones</a>
                <a href="#5" className='font-semibold'>Security Staff</a>
            </div>

            <div className='lg:pr-[15svw] pr-[5svw] p-14 md:p-2 '>
                <h3 className='font-bold text-2xl mb-14 '>Security at <br />
                    Stunify Music Festival</h3>
                <p className='my-6'>At Stunify Music Festival 2024, your safety is our top priority. Our SECURE.Y initiative is designed to provide a secure and comfortable environment for all attendees.</p>

                <div id="1">
                    <h4 className='font-bold'>Security Checkpoints:</h4>
                    <p>We have enhanced security checkpoints at all entry points. All bags will be checked, and security personnel will be available to assist with any concerns.</p>
                </div>

                <div className='mt-8' id="2">
                    <h4 className='font-bold'>Emergency Services:</h4>
                    <p>On-site medical tents and first aid stations are staffed by professional medical personnel ready to handle any health emergencies or minor injuries.</p>
                </div>

                <div className='mt-8' id="3">
                    <h4 className='font-bold'>Lost and Found:</h4>
                    <p>A dedicated lost and found station is available to help you retrieve any misplaced items, ensuring nothing spoils your festival experience.</p>
                </div>

                <div className='mt-8' id="4">
                    <h4 className='font-bold'>Safe Zones:</h4>
                    <p>Designated safe zones throughout the festival grounds provide a place where attendees can go if they need assistance or feel unsafe. These areas are clearly marked and staffed by trained personnel.</p>
                </div>
                <div className='mt-8' id="5">
                    <h4 className='font-bold'>Security Staff:</h4>
                    <p>Our security staff is visibly present throughout the festival to maintain a safe environment and assist with any issues that may arise.</p>
                </div>
            </div>
        </div>
        <Footer/>

        </div>
        
    );
}

export default Security;
