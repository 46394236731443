import React, { useEffect, useState } from "react";
import Card from "./Card";
import { useInView } from "react-intersection-observer";
import LineupAnimattedBg from "./LineupAnimattedBg";
import { useScroll } from "../Context/ScrollContext";
import { lineups } from "../Data/LineUp";
import { Link } from "react-router-dom";
import { useTheme } from "../Context/ThemeContext";

const Lineup = () => {
  const [day, setDay] = useState(1);
  const { LineUpRef } = useScroll();
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
  }
  const {videoLoaded} = useTheme()
  // const [animation, setAnimation] = useState();
  // const [animation2, setAnimation2] = useState();

  // const { inView, ref } = useInView();
  // useEffect(() => {
  //   if (inView) {
  //     setAnimation({ x: [-500, 0], y: [-500, 0] });
  //     setAnimation2({ x: [500, 0], y: [500, 0] });
  //   } else {
  //     setAnimation({ x: [0, -500], y: [0, -500] });
  //     setAnimation2({ x: [0, 500], y: [0, 500] });
  //   }
  // }, [inView]);

  return (
    <div ref={LineUpRef} className="relative overflow-hidden">
      <div  className="absolute top-[50%]"></div>
      <div
        className="flex flex-col font-Syne text-center my-8 max-md:items-center lg:px-[15svw] px-[5svw] z-20"
        id="lineup">
        <h3 className="text-[#498BEC] dark:text-[#4FD7E4] text-4xl font-semibold">
          LINE-UP
        </h3>
        <div className="flex flex-row justify-between  p-5 my-8 max-md:flex-col max-md:gap-9  max-md:items-center">
          <div className="flex md:w-2/5 md:text-left">
            <p className="md:max-w-[375px] dark:text-white font-Sora font-normal max-md:text-center z-20 max-md:w-full">
              Immerse yourself in our dynamic two-day lineup, designed to bring you a
              spectrum of acoustic and electronic vibrations.
            </p>
          </div>
          <div className="flex  gap-5 justify-between items-end w-fit  max-md:justify-between z-20">
            <div onClick={() => setDay(1)} className=" cursor-pointer">
              <p
                className={`${
                  day === 1 ? "selected-day font-extrabold w-fit " : "unselected-day"
                }`}>
                · Day 1 ·
              </p>
              <p
                className={`${
                  day === 1 ? "selected-day font-semibold" : "unselected-day"
                }`}>
                August 10
              </p>
            </div>
            <div onClick={() => setDay(2)} className=" cursor-pointer">
              <p
                className={`${
                  day === 2 ? "selected-day font-extrabold" : "unselected-day"
                }`}>
                · Day 2 ·
              </p>
              <p
                className={`${
                  day === 2 ? "selected-day font-semibold" : "unselected-day"
                }`}>
                August 11
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 grid-rows-3 text-center gap-5 p-5 mx-auto w-full max-md:grid-cols-2 max-md:p-0 z-20  ">
          {lineups.map((lineup, index) => {
            return (
              lineup.day === day && (
                <Card
                  key={index}
                  name={lineup.name}
                  image={videoLoaded? lineup.img : "../images/lineup1.png"}
                  desciption={lineup.desciption}
                />
              )
            );
          })}
        </div>
        <div className="text-center z-20 mt-8 ">
          <Link
            to="https://www.instagram.com/stunify.festival/"
            className="z-20 p-4 uppercase border-[1px] bgFadeinLeft hover:text-white dark:text-white dark:border-white border-[#232534] rounded-[29px] cursor-pointer w-[267px] h-[58px] mt-28 text-[#232534] font-Syne font-medium text-lg">
            visit our instagram
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Lineup;
