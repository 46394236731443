import React, { useEffect } from 'react'
import "./BurgerMenu.css"

const BurgerMenu = ({ toggleMenu , menuOpen , theme }) => {
  const strokeColor = (theme) => {
     if(theme === "light" && menuOpen === false){
       return "!stroke-[#498BEC]"
     }
     else{
      return "!stroke-white"
     }
  }
    useEffect(() => {
        if (menuOpen) {
          document.body.classList.add('menu-open');
        } else {
          document.body.classList.remove('menu-open');
        }
      }, [menuOpen]);
  return (
    <svg className="vbp-header-menu-button__svg  ml-5 z-50 " onClick={() => toggleMenu(!menuOpen)} width="30" height="30">
      <line x1="0" y1="50%" x2="100%" y2="50%" className={`top ${strokeColor(theme)} `} shapeRendering="crispEdges" />
      <line x1="0" y1="50%" x2="100%" y2="50%" className={`middle ${strokeColor(theme)} `} shapeRendering="crispEdges" />
      <line x1="0" y1="50%" x2="100%" y2="50%" className={`bottom ${strokeColor(theme)} `} shapeRendering="crispEdges" />
    </svg>
  )
}

export default BurgerMenu
