import React from "react";

const ArrowLeft = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="30"
        viewBox="0 0 29 30"
        className="dark:fill-white"
        fill="none">
        <rect
          x="1"
          y="1"
          width="26.83"
          height="26.83"
          rx="13.415"
          stroke="#232534"
          strokeWidth="2"
        />
        <g filter="url(#filter0_d_894_336)">
          <path
            d="M10.6216 14.1116L16.2298 8.50342L16.6122 8.8858L11.3863 14.1116L16.6122 19.3374L16.2298 19.7198L10.6216 14.1116Z"
            fill="#232534"
          />
          <path
            d="M10.268 13.7581L9.91448 14.1116L10.268 14.4652L15.8762 20.0734L16.2298 20.4269L16.5833 20.0734L16.9657 19.691L17.3193 19.3374L16.9657 18.9839L12.0934 14.1116L16.9657 9.23935L17.3193 8.8858L16.9657 8.53224L16.5833 8.14987L16.2298 7.79631L15.8762 8.14986L10.268 13.7581Z"
            stroke="#232534"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_894_336"
            x="5.2074"
            y="7.08936"
            width="16.819"
            height="22.0444"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.533333 0 0 0 0 0.666667 0 0 0 0 0.921569 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_894_336"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_894_336"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default ArrowLeft;
