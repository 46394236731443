import React, { createContext, useContext, useRef } from "react";

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const heroRef = useRef(null);
  const LineUpRef = useRef(null);
  const TicketRef = useRef(null)
  const VillageRef = useRef(null)
  const ContactUsRef = useRef(null)

  const scrollto = (ref,behavior) => {
    let offset = 0;

    switch (ref) {
      case heroRef:
        offset = 0;
        break;

      case LineUpRef:
        offset = 100;
        break;

      default:
        offset = 0;
    }

    window.scrollTo({
      top: ref.current.offsetTop + offset,
      behavior: behavior || "smooth",
    });
  };

  return (
    <ScrollContext.Provider value={{ heroRef, scrollto, LineUpRef , TicketRef , VillageRef , ContactUsRef}}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);
