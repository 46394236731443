import React from "react";
import Hero from "../Components/Hero";
import Scroll from "../Components/Scroll";
import Lineup from "../Components/Lineup";
import Tickets from "../Components/Tickets";
import StunifyVillage from "../Components/StunifyVillage";
import ContactUs from "../Components/ContactUs";
import StunifyAnimatedBg from "../Components/StunifyAnimatedBg";
import Footer from "../Components/Footer";
import { useTheme } from "../Context/ThemeContext";

const Home = () => {
  const { videoBg } = useTheme();
  return (
    <div className="relative ">
      <StunifyAnimatedBg
        videoBg={videoBg}
        className={
          "absolute w-[60dvw] z-10 max-md:hidden  top-[-42svh] blur-sm left-[-45dvw] rounded-full overflow-hidden "
        }
      />
      <StunifyAnimatedBg
        videoBg={videoBg}
        className={
          "absolute w-[60dvw] z-10 max-md:hidden  top-[-19svh] blur-sm right-[-40dvw]"
        }
      />
      <Hero />
      <Scroll />
      <Lineup />
      <Tickets />
      {/* <Hotels /> */}
      <StunifyVillage />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
