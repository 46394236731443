import React from 'react';

const Card = ({name, image}) => {
    return (
        <div className='relative flex rounded-xl flex-col w-50 dark:border-[1px] dark:border-white '>
            <img src={image} className='rounded-xl dark:hidden' alt='dj'/>
            <img src={image} className='rounded-xl hidden dark:block' alt='dj'/>
            <div className='absolute rounded-b-lg flex-col flex bg-gradient-to-t text-white from-[#71a1db]/100 to-[#EBF3F7]/0  h-[30%]   w-full  bottom-0  px-2  justify-center items-start'>
                <h4 className='text-md font-semibold '>{name}</h4>
            </div>
        </div>
    );
}

export default Card;
