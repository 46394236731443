export const  stunifyVillageInfos = [
    {
      title: "ART.Y",
      description:
        "Immerse yourself in the vibrant world of interactive art at Stunify Music Festival 2024. Our collaboration with renowned art schools and talented artists brings a unique, creative dimension to the festival, transforming the grounds into a living gallery of dynamic art pieces.",
    },
    {
      title: "BEAUTY.Y",
      description:
        "At Stunify Music Festival 2024, we believe in creating a holistic festival experience that goes beyond music. Our Beauty and Wellness Zone, Beauty.Y, is dedicated to helping you look and feel your best throughout the festival",
    },
    {
      title: "WELLNESS.Y",
      description:
        "Stunify Music Festival 2024 also prioritizes your well-being with our Wellness.Y zone, designed to help you relax and rejuvenate",
    },
    {
      title: "FOOD.Y",
      description:
        "Savor a variety of culinary delights from local and international cuisines at Stunify Music Festival 2024. Our food zone offers an array of delicious options to satisfy every palate.",
    },
    {
      title: "DRINK.Y",
      description:
        "Explore our selection of refreshing beverages, perfect for keeping you hydrated and energized throughout the festival. Enjoy a variety of cocktails, both alcoholic and non-alcoholic, crafted to enhance your Stunify experience.",
    },
    {
      title: "VIP.Y",
      description:
        "Elevate your Stunify Music Festival 2024 experience with our VIP.Y offering. Enjoy the festival in style with our exclusive VIP tables, designed to provide premium amenities and the best views of the stages.",
    },
    {
      title: "SUSTAINABILITY.Y",
      description:
        "Stunify Music Festival is teaming up with WWF Tunisia to promote environmental sustainability. WWF Tunisia will have a space at the festival to raise awareness about conservation efforts.A portion of the festival's proceeds will support WWF Tunisia's projects like marine conservation, reforestation, and sustainable agriculture. By attending, you're enjoying music and helping protect Tunisia's ecosystems and wildlife.",
    }
  ];